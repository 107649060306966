<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    :show="isLoading"
  >
    <validation-observer
      ref="programmeSubjectGroupSaveFormRef"
      #default="{invalid}"
    >
      <b-modal
        id="programmeSubjectGroupSaveModal"
        centered
        no-close-on-backdrop
        size="lg"
        :title="isCreated ? `Thêm ${resourceName.fullName}` : `Cập nhật ${resourceName.fullName}`"
        @show="onShow"
        @hide="onHide"
      >
        <b-form>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group label-for="name">
                <template v-slot:label>
                  Tên nhóm <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Tên nhóm"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="targetData.name"
                    name="name"
                    :state="getElementState(errors)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group label-for="code">
                <template v-slot:label>
                  Mã nhóm <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Mã nhóm"
                  rules="required"
                >
                  <b-form-input
                    id="code"
                    v-model="targetData.code"
                    name="code"
                    :state="getElementState(errors)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group label-for="courseSemesterId">
                <template v-slot:label>
                  Học kỳ <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Học kỳ"
                  rules="required"
                >
                  <v-select
                    v-model="targetData.courseSemesterId"
                    :options="courseSemesters"
                    :reduce="option => option.value"
                  />
                  <b-form-invalid-feedback :state="getElementState(errors)">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group label-for="status">
                <template v-slot:label>
                  Trạng thái <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Trạng thái"
                  rules="required"
                >
                  <v-select
                    v-model="targetData.status"
                    :options="statuses"
                    :reduce="option => option.value"
                  />
                  <b-form-invalid-feedback :state="getElementState(errors)">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group label-for="minCreditNum">
                <template v-slot:label>
                  Số tiết tối thiểu <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Số tiết tối thiểu"
                  rules="required"
                >
                  <b-form-input
                    id="minCreditNum"
                    v-model="targetData.minCreditNum"
                    name="minCreditNum"
                    :state="getElementState(errors)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Số thứ tự"
                label-for="orderNo"
              >
                <b-form-input
                  id="orderNo"
                  v-model="targetData.orderNo"
                  name="orderNo"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-end">
            <b-button
              v-show="isCreated"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onSave"
            >
              <span class="text-right">
                <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
              </span>
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onSave('hide')"
            >
              <span class="text-right">
                <feather-icon icon="CheckIcon" /> Lưu lại
              </span>
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$bvModal.hide('programmeSubjectGroupSaveModal')"
            >
              <span class="text-right">
                <feather-icon icon="XIcon" /> Hủy
              </span>
            </b-button>
          </div>
        </template>
      </b-modal>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BForm, BFormGroup, BModal, BOverlay, BFormInvalidFeedback, BButton, BRow, BCol, BFormInput,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'
import { getUser } from '@/auth/utils'

export const Attr = Object.freeze({
  store: 'programmeSubjectGroup',
})
export default {
  name: 'ProgrammeSubjectGroupSave',
  components: {
    BFormGroup,
    BModal,
    BForm,
    BFormInput,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    mainData: {
      type: Object,
      default: undefined,
    },
    extData: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetData: {
        id: null,
        name: '',
        code: '',
        programmeId: this.extData.parentId,
        courseSemesterId: '',
        minCreditNum: 1,
        orderNo: 1,
        status: 1,
      },
      required,
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
      statuses: `${Attr.store}/statuses`,
      maxOrderNo: `${Attr.store}/maxOrderNo`,
      resourceName: `${Attr.store}/resourceName`,
      courseSemesters: 'dropdown/courseSemesters',
    }),
    isCreated() {
      return !this.mainData
    },
  },
  methods: {
    ...mapActions({
      createData: `${Attr.store}/createData`,
      updateData: `${Attr.store}/updateData`,
      getCourseSemesters: 'dropdown/getCourseSemesters',
      getMaxOrderNo: `${Attr.store}/getMaxOrderNo`,
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      this.isLoading = true
      if (this.mainData) {
        this.targetData = this.mainData
      }
      if (this.isCreated) {
        await this.getMaxOrderNo(this.extData.parentId)
        this.targetData.orderNo = parseInt(this.maxOrderNo, 10) + 1
      }
      await this.getCourseSemesters({ organizationId: getUser().orgId, courseId: this.extData.courseId, status: '' })
      this.isLoading = false
    },
    onHide() {
      this.$refs
        .programmeSubjectGroupSaveFormRef
        .reset()
      this.targetData = {
        id: null,
        name: '',
        code: '',
        programmeId: this.extData.parentId,
        courseSemesterId: null,
        minCreditNum: 1,
        orderNo: 1,
        status: 1,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .programmeSubjectGroupSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createData(this.targetData)
            : await this.updateData(this.targetData)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('programmeSubjectGroupSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
