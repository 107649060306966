<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
  >
  <div>
    <b-card>
      <b-row>
        <b-col cols="4">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="Nhập mã thi LMS"
            label-for="codeLms"
          >
            <b-form-input
              id="codeLms"
              v-model="codeLms"
              name="codeLms"
              placeholder="Nhập mã cuộc thi LMS"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="Chọn loại điểm đồng bộ"
            label-for="componentScore"
          >
            <v-select
              v-model="componentScoreId"
              :options="dropdownComponentScore"
              :reduce="option => option.value"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-button class="float-right ml-1" variant="success" @click="syncData">
                            <span class="text-nowrap text-right">
                              <feather-icon icon="RefreshCwIcon" /> Thực hiện đồng bộ
                            </span>
          </b-button>
          <b-button class="float-right" variant="primary" @click="searchData">
                            <span class="text-nowrap text-right">
                              <feather-icon  icon="SearchIcon" /> Xem điểm thi
                            </span>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <vue-good-table  :columns="table.fields"
                           :rows="table.items"
                           :line-numbers="true"
                           :pagination-options="{
                                        enabled: true,
                                        mode: 'pages',
                                  }">
            <div slot="emptystate" style="text-align: center; font-weight: bold">
              Không có bản ghi nào !
            </div>
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị {{serverParams.perPage * (serverParams.page-1) +1}}
                        đến {{(serverParams.perPage * (serverParams.page) >= total)? total : serverParams.perPage * (serverParams.page)}} &nbsp; của {{ totalRecords }} bản ghi
                      </span>
                </div>
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Hiển thị </span>
                  <b-form-select
                    v-model="serverParams.perPage"
                    :options="['10', '20', '50']"
                    class="mx-1"
                    @input="(value) => props.perPageChanged({ currentPerPage: value })"
                  />
                  <span class="text-nowrap"> bản ghi/trang</span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="totalRecords"
                    :per-page="serverParams.perPage"
                    class="mt-1 mb-0"
                    @input="(value) => props.pageChanged({ currentPage: value })"
                  />
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
  </b-overlay>
</template>

<script>
import {
  BBreadcrumb, BButton, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow, BFormGroup, BFormInput
} from 'bootstrap-vue'

import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'
import vSelect from "vue-select";

export default {
  name: 'SyncScoreLms',
  components: {
    vSelect,
    BBreadcrumb,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,BFormGroup, BFormInput
  },
  props: {
    dataSyncScoreLms: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      codeLms: "",
      serverParams: {
        page: 1,
        perPage: 10,
      },
      totalRecords: 0,
      total: 0,
      table: {
        fields: [
          {
            field: 'ten_cuoc_thi', label: 'Tên cuộc thi', sortable: false, type: 'string',
          },
          {
            field: 'user_name', label: 'Mã học viên', sortable: false, type: 'string',
          },
          {
            field: 'full_name', label: 'Tên học viên', sortable: false, type: 'string',
          },
          {
            field: 'so_cau_dung', label: 'Số câu đúng', sortable: false, type: 'string',
          },
          {
            field: 'so_cau_sai', label: 'Số câu sai', sortable: false, type: 'string',
          },
          {
            field: 'thang_diem', label: 'Thang điểm', sortable: false, type: 'string',
          },
          {
            field: 'diem_thi', label: 'Điểm', sortable: false, type: 'string',
          },
        ],
        items: [],
      },
      componentScoreId: null,
      dropdownComponentScore: [],
    }
  },
  computed: {
    ...mapGetters({
    }),

  },
  async created() {
    // init data FROM API
    const param = {
      creditClassId: this.dataSyncScoreLms.id,
      generalScoreType: this.dataSyncScoreLms.isPractice == '1' ? 2: 1
    }
    const data = await this.getDataByCreditClass(param)
    this.dropdownComponentScore = data
  },

  methods: {
    ...mapActions({
      'getDiemThiByCode': 'creditClass/getDiemThiByCode',
      'getDataByCreditClass': 'componentScore/getDataByCreditClass',
      'syncDataLms' : 'creditClass/syncDataLms'
    }),

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },

    getStatusName(id) {
      return this.statuses.find(status => status.id === id).name
    },


    async syncData() {
       let students = this.table.items.map(item => {
         return {
           username: item.user_name,
           score: item.diem_thi
         }
       })
       const body = {
         students: students,
         creditClassId: this.dataSyncScoreLms.id,
         componentScoreId: this.componentScoreId,
         codeExaminationLms: this.codeLms,
       }
       const response = await this.syncDataLms(body);
       if(response.isSuccessful) {
         this.$toast({
           component: ToastificationContent,
           props: {
             title: response.message,
             icon: 'CheckIcon',
             variant: 'success',
           },
         })
       } else {
         this.$toast({
           component: ToastificationContent,
           props: {
             title: 'Có lỗi xảy ra',
             icon: 'XCircleIcon',
             variant: 'danger',
           },
         })
       }
    },

    async searchData() {
      this.isLoading = true
      const body = {
        code: this.codeLms
      }
      const response = await this.getDiemThiByCode(body)
      if (response) {
        this.table.items = JSON.parse(response)
      }
      this.isLoading = false
    },
  },
}
</script>
<style>

</style>
