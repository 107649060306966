<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row v-if="creatable">
              <b-col cols="12">
                <div class="w-100 d-flex justify-content-end align-items-center">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    @click="openModal()"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="UploadIcon" /> Tải lên
                    </span>
                  </b-button>
                  <b-form-file
                    ref="inputFileRef"
                    plain
                    multiple
                    :hidden="true"
                    @change="uploadFiles"
                  />
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                    đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  style-class="vgt-table striped bordered"
                  row-style-class="vgt-row"
                  :columns="columns"
                  :rows="dataSources"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  :line-numbers="true"
                  :select-options="selectOptions"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  @on-selected-rows-change="selectionChanged"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'name'">
                      <b-media no-body>
                        <b-media-aside class="mr-1">
                          <b-img
                            :src="getIconFile(props.formattedRow[props.column.field])"
                            height="23"
                          />
                        </b-media-aside>
                        <b-media-body class="my-auto">
                          <h6 class="media-body mb-0">
                            {{ props.formattedRow[props.column.field] }}
                          </h6>
                        </b-media-body>
                      </b-media>
                    </span>
                    <span
                      v-else-if="props.column.field === 'action'"
                      class="d-flex align-items-center justify-content-center flex-wrap"
                    >
                      <b-button
                        v-b-tooltip.hover.top="'Tải về'"
                        variant="success"
                        class="btn-icon"
                        style="margin-left: 6px;"
                        size="sm"
                        @click="onDownload(props.row.path)"
                      >
                        <feather-icon icon="DownloadIcon" />
                      </b-button>
                      <b-button  v-b-tooltip.hover title="Chỉnh sửa" style="margin-left: 6px;" placement="bottom" variant="primary" class="btn-icon" size="sm"
                          @click="editItem(props.row)">
                                <feather-icon icon="EditIcon" />
                      </b-button>
                      <b-button
                        v-if="deletable"
                        v-b-tooltip.hover.top="'Xóa'"
                        variant="danger"
                        class="btn-icon"
                        size="sm"
                        style="margin-left: 6px;"
                        @click="onDelete(props.row.id)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="['10', '20', '50']"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <b-modal
      ref="modal-teacher-document"
      id="modal-teacher-document"
      ok-only
      ok-title="Chấp nhận"
      centered
      size="lg"
      title="Thêm/Sửa giáo trình"
      :hide-footer=true
    >
      <FormTeacherDocument @saveDataSuccess="saveDataSuccess" :dataSendForm="dataSendForm"/>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BFormFile,
  BFormSelect, BOverlay,
  BPagination,
  BRow,
  VBTooltip,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import { getUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getIconFile, hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import FormTeacherDocument from '@/views/teacher-document/FormTeacherDocument.vue'

export default {
  name: 'TeacherDocument',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormFile,
    BOverlay,
    BPagination,
    BFormSelect,
    BButton,
    BCard,
    BContainer,
    BRow,
    BCol,
    VueGoodTable,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    FormTeacherDocument,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 20,
        teacherId: null,
        name: null,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      paginationOptions: {
        enabled: true,
      },
      columns: [
        {
          label: 'Tiêu đề',
          field: 'title',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          thClass: 'text-left',
        },
        {
          label: 'Mô tả',
          field: 'description',
          thClass: 'text-left',
        },
        {
          label: 'Ngày tạo',
          field: 'createdAt',
          sortable: true,
          thClass: 'text-right',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          thClass: 'text-center',
        },
      ],
      selectOptions: {
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      },
      dataSendForm: {},
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'teacherDocument/documents',
      totalRows: 'teacherDocument/totalRows',
      url: 'teacherDocument/url',
    }),
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.TEACHER_DOCUMENT)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.TEACHER_DOCUMENT)
    },
  },
  async created() {
    this.updateParams({ teacherId: this.user.teacherId })
    await this.getDataSourcesFromStore()
  },
  methods: {
    ...mapActions({
      getTeacherDocuments: 'teacherDocument/getTeacherDocuments',
      createTeacherDocuments: 'teacherDocument/createTeacherDocuments',
      downloadTeacherDocument: 'teacherDocument/downloadTeacherDocument',
      deleteTeacherDocument: 'teacherDocument/deleteTeacherDocument',
    }),
    async uploadFiles(e) {
      const formData = new FormData()
      const { files } = e.target
      if (files.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File giáo trình đang bị trống',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }

      files.forEach(file => formData.append('files', file))
      this.isLoading = true
      try {
        const response = await this.createTeacherDocuments(formData)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            await this.getTeacherDocuments(this.filter)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (ex) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${ex.code}] ${ex.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
        this.resetInputFile()
      }
    },
    resetInputFile() {
      this.$refs.inputFileRef.reset()
    },
    async onDownload(objectPath) {
      this.isLoading = true
      try {
        await this.downloadTeacherDocument({ objectPath })
        if (this.url) {
          window.open(this.url, '_blank')
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onDelete(id) {
      this.$swal({
        title: 'Bạn chắc chắn muốn xóa tài liệu?',
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            this.isLoading = true
            try {
              const response = await this.deleteTeacherDocument(id)
              if (response) {
                const { isSuccessful, message } = response
                if (isSuccessful) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  await this.getTeacherDocuments(this.filter)
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              }
            } catch (e) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `[${e.code}] ${e.message}`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            } finally {
              this.isLoading = false
            }
          }
        })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getDataSourcesFromStore()
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getTeacherDocuments(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getDataSourcesFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        if (field === 'name') {
          this.updateParams({ sort: `1_${type}` })
        }
        if (field === 'createdAt') {
          this.updateParams({ sort: `2_${type}` })
        }
        await this.getDataSourcesFromStore()
      }
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'title')) this.updateParams({ title: columnFilters.title })
      await this.getDataSourcesFromStore()
    },
    selectionChanged(params) {
      const data = []
      if (params.selectedRows.length > 0) {
        params.selectedRows.forEach(element => data.push(element.mediaId))
      }
      this.$emit('selectDocuments', data)
    },
    getIconFile,

    openModal() {
      this.dataSendForm = {
        id: null,
        rowNum: null,
        name: '',
        mediaId: null,
        mediaName: '',
        url: '',
      }
      this.$bvModal.show('modal-teacher-document')
    },

    async editItem(data) {
      this.$bvModal.show('modal-teacher-document')
      this.dataSendForm = data
    },

    async saveDataSuccess(data) {
      if (data) {
        this.$bvModal.hide('modal-teacher-document')
        await this.getDataSourcesFromStore()
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
