<template>
  <div style="height: 100vh;">
    <b-overlay
      variant="white"
      spinner-variant="primary"
      blur="0"
      :show="isLoading"
      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
    >
      <b-container fluid style="height: 100%;">
        <b-row>
          <b-col cols="12" class="h-100">
            <b-card no-body class="h-100">
              <b-tabs content-class="h-100 d-flex flex-column" align="center" justified>
                <b-tab title="CHƯƠNG TRÌNH ĐÀO TẠO" active>
                  <b-card-body class="flex-grow-1">
                    <programme />
                  </b-card-body>
                </b-tab>
                <b-tab title="LỚP HỌC PHẦN">
                  <b-card-body class="flex-grow-1">
                    <credit-class/>
                  </b-card-body>
                </b-tab>
                <b-tab title="THỜI KHÓA BIỂU">
                  <b-card-body class="flex-grow-1">
                    <scheduler />
                  </b-card-body>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCol, BRow, BContainer, BTab, BTabs, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Programme from '@/views/programme/Programme.vue'
import CreditClass from '@/views/credit-class/CreditClass.vue'
import Scheduler from '@/views/scheduler/Scheduler.vue'
export default {
  name: 'Index',
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BContainer,
    BTab,
    BTabs,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    Programme,
    CreditClass,
    Scheduler,
  },
}
</script>
<style lang="scss">
</style>
