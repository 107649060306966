<template>

</template>

<script>
import Ripple from "vue-ripple-directive";
import {getUser} from "@/auth/utils";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "ProgressLms",
  directives: {
    Ripple,
  },
  props: {
    dataSendProgressLms: {
      type: Object,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      lmsCourseId: null
    }
  },
  async created() {
    this.lmsCourseId = this.dataSendProgressLms.lmsCourseId;
    const data = await this.getMainData( { lmsCourseId: this.lmsCourseId } )
  },
  computed: {
  },
  methods: {
    ...mapActions({
      getMainData: 'creditClass/getProgressLms'
    })
  }
}
</script>

<style scoped>
</style>
