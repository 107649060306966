<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-form>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-row>
                    <b-col cols="3">
                      <b-form-group
                        label-for="trainingSystemId"
                      >
                        <v-select
                          v-model="filter.trainingSystemId"
                          :options="trainingSystems"
                          :reduce="option => option.value"
                          placeholder="Chương trình đào tạo/Bồi dưỡng"
                          @input="onTrainingSystemsChange"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="3">
                      <b-form-group
                        label-for="courseId"
                      >
                        <v-select
                          v-model="filter.courseId"
                          :options="courses"
                          :reduce="option => option.value"
                          placeholder="Khóa học"
                          @input="onCoursesChange"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="3">
                      <b-form-group
                          label-for="trainingTypeId"
                      >
                        <v-select
                            v-model="filter.trainingTypeId"
                            :options="trainingTypes"
                            :reduce="option => option.value"
                            placeholder="Loại chương trình"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="3">
                      <div class="text-right">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="mb-0 mt-0 mb-sm-1"
                          @click="onSearchMainData"
                        >
                          <feather-icon icon="FilterIcon" /> Lọc
                        </b-button>
                        <b-button
                          v-if="true"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          v-b-modal.programmeSaveModal
                          variant="success"
                          class="mt-0 ml-1 mb-md-1"
                          @click="onCreateProgramme"
                        >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="PlusIcon" /> Thêm mới
                      </span>
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  v-show="false"
                >
                  <b-row>
                    <b-col cols="6">
                      <b-form-group
                        label-for="departmentId"
                      >
                        <v-select
                          v-model="filter.departmentId"
                          :options="departments"
                          :reduce="option => option.value"
                          placeholder="Khoa/Bộ môn"
                          @input="onDepartmentChange"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        label-for="majorId"
                      >
                        <v-select
                          v-model="filter.majorId"
                          :options="majors"
                          :reduce="option => option.value"
                          placeholder="Ngành học"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-form>
            <b-row class="">
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-1">
                  <span class="text-nowrap"> Hiển thị {{filter.itemsPerPage * (filter.currentPage-1) +1}}
                    đến {{(filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage)}} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  row-style-class="vgt-row"
                  style-class="vgt-table striped bordered"
                  :columns="columns"
                  :rows="programmes"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  :line-numbers="true"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <span v-else-if="props.column.field === 'totalCreditNum'">
                      {{ parseInt(props.row.totalCreditNum, 10) + '/' + props.row.avgCreditNum }}
                    </span>
                    <span
                      v-else-if="props.column.field === 'action'"
                      class="thead-group-action"
                    >
                      <b-button-group>
                        <b-button
                          v-if="updatable"
                          v-b-modal.programmeSaveModal
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="btn-icon"
                          size="sm"
                          title="Chỉnh sửa"
                          @click="onEditProgramme(props.row)"
                        >
                          <feather-icon icon="Edit2Icon" />
                        </b-button>
                        <b-dropdown
                          right
                          variant="success"
                        >
                          <b-dropdown-item
                            v-if="programmeSubjectReadable"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="info"
                            class="btn-icon"
                            size="sm"
                            @click="onListSubjects(props.row)"
                          >
                            <feather-icon icon="BookOpenIcon" />
                            Danh sách học phần
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="programmeSubjectCreatable"
                            variant="success"
                            class="btn-icon"
                            size="sm"
                            @click="onImportSubject(props.row)"
                          >
                            <feather-icon icon="FileIcon" />
                            Import học phần
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="creatable"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            v-b-modal.programmeCopyModal
                            variant="success"
                            class="btn-icon"
                            size="sm"
                            @click="onCopyProgramme(props.row)"
                          >
                            <feather-icon icon="CopyIcon" />
                            Sao chép CTĐT
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="deletable"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="danger"
                            class="btn-icon"
                            size="sm"
                            @click="onDeleteProgramme(props.row)"
                          >
                            <feather-icon icon="TrashIcon" />
                            Xóa CTĐT
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-button-group>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{filter.itemsPerPage * (filter.currentPage-1) +1}}
                          đến {{(filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage)}} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <ProgrammeSave
      ref="programmeSaveModalRef"
      :programme="currentMainData"
      @succeed="onSucceed"
    />
    <ProgrammeCopy
      ref="programmeCopyModalRef"
      :main-data="currentMainData"
      @succeed="onSucceed"
    />
    <b-modal
      id="modal-list-subject"
      ref="modal-list-subject"
      ok-title="Chấp nhận"
      cancel-title="Đóng"
      centered
      size="xl"
      :hide-header="true"
      @ok="onSucceed"
    >
      <ProgrammeSubject :data-send="currentMainData" />
      <ProgrammeSubjectGroup :data-send="currentMainData" />
    </b-modal>
    <b-modal
      id="modal-import-subject"
      ref="modal-import-subject"
      ok-title="Chấp nhận"
      cancel-title="Đóng"
      centered
      size="xl"
      :hide-header="true"
    >
      <ProgrammeSubjectImport :data-send="currentMainData" />
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BDropdown,
  BButtonGroup,
  BDropdownItem,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  VBTooltip,
  BFormGroup, BForm,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import ProgrammeSave from '@/views/programme/ProgrammeSave.vue'
import ProgrammeCopy from '@/views/programme/ProgrammeCopy.vue'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import ProgrammeSubject from '@/views/programme-subject/ProgrammeSubject.vue'
import ProgrammeSubjectGroup from '@/views/programme-subject-group/ProgrammeSubjectGroup.vue'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import ProgrammeSubjectImport from '../programme-subject/importProgrammeSubject/ProgrammeSubjectImport.vue'
import {TRAINING_TYPES} from '@/const/status';

export default {
  name: 'Programme',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    ProgrammeSubjectGroup,
    ProgrammeSubject,
    ProgrammeCopy,
    ProgrammeSave,
    BButton,
    BDropdown,
    BButtonGroup,
    BDropdownItem,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BFormGroup,
    BForm,
    vSelect,
    ProgrammeSubjectImport,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        organizationId: null,
        trainingSystemId: null,
        courseId: null,
        courseSemesterId: null,
        departmentId: null,
        majorId: null,
        code: null,
        name: null,
        status: null,
        sort: null,
        trainingTypeId: null,
      },
      currentMainData: undefined,
      currentName: '',
      currentCode: '',
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 100],
    }
  },
  computed: {
    ...mapGetters({
      programmes: 'programme/programmes',
      totalRows: 'programme/totalRows',
      statuses: 'programme/statuses',
      resourceName: 'programme/resourceName',
      courses: 'dropdown/courses',
      trainingSystems: 'dropdown/trainingSystems',
      courseSemesters: 'dropdown/courseSemesters',
      departments: 'dropdown/departments',
      majors: 'dropdown/majors',
    }),
    trainingTypes() {
      return TRAINING_TYPES
    },
    statusFilterDropdownItems() {
      return this.statuses.map(status => ({ value: status.value, text: status.label }))
    },
    columns() {
      return [
        {
          label: `Mã ${this.resourceName.shortName}`,
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Tìm theo mã',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: `Tên ${this.resourceName.shortName}`,
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Tìm theo tên',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Hệ ĐT',
          field: 'trainingSystemName',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Khóa',
          field: 'courseName',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        // {
        //   label: 'Ngành học',
        //   field: 'majorName',
        //   filterOptions: {
        //     enabled: false,
        //     trigger: 'enter',
        //   },
        //   sortable: true,
        //   thClass: 'text-center',
        // },
        {
          label: 'Số TC',
          field: 'totalCreditNum',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.statusFilterDropdownItems,
            placeholder: 'Tất cả',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Thao tác',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ]
    },
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.PROGRAMME)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.PROGRAMME)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.PROGRAMME)
    },
    programmeSubjectReadable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.PROGRAMME_SUBJECTS)
    },
    programmeSubjectCreatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.PROGRAMME_SUBJECTS)
    },
  },
  async created() {
    this.updateParams({ organizationId: this.user.orgId })
    this.isLoading = true
    try {
      await this.getTrainingSystems({ organizationId: this.filter.organizationId })
      // Rào lại để tối ưu
      // this.filter.courseId = null
      // if (this.trainingSystems.length > 0) {
      //   this.filter.trainingSystemId = this.trainingSystems[0].value
      //   await this.getCourses(this.filter)
      // }
      //
      // this.filter.majorId = null
      // if (this.departments.length > 0) {
      //   this.filter.departmentId = this.departments[0].value
      //   this.filter.majorId = null
      //   await this.getMajors(this.filter)
      // }

      // Rào lại để tối ưu
      // await Promise.all([
      //   this.getProgrammes(this.filter),
      // ])
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  mounted() {
    const grid = document.querySelector('div.vgt-responsive')
    grid.classList.add('overlay')
    grid.classList.add('custom')
  },
  methods: {
    ...mapActions({
      getProgrammes: 'programme/getProgrammes',
      deleteProgramme: 'programme/deleteProgramme',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getCourseSemesters: 'dropdown/getCourseSemesters',
      getDepartments: 'dropdown/getDepartments',
      getMajors: 'dropdown/getMajors',
    }),
    async onTrainingSystemsChange(event) {
      this.filter.courseId = null
      await this.getCourses({ organizationId: getUser().orgId, trainingSystemId: event })
    },
    async onCoursesChange(event) {
      this.filter.courseSemesterId = null
      // await this.getCourseSemesters({ organizationId: getUser().orgId, courseId: event })
    },
    async onDepartmentChange(event) {
      this.filter.majorId = null
      await this.getMajors({ organizationId: getUser().orgId, departmentId: event })
    },
    onCreateProgramme() {
      this.currentMainData = undefined
    },
    onEditProgramme(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
    },
    onCopyProgramme(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
    },
    onListSubjects(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
      this.$refs['modal-list-subject'].show()
    },
    onDeleteProgramme(programme) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa ${this.resourceName.fullName} <span class="text-danger">${programme.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteProgramme(programme.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getProgrammes(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getProgrammesFromStore()
    },
    async getProgrammesFromStore() {
      this.isLoading = true
      try {
        await this.getProgrammes(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getProgrammesFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)

        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getProgrammesFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getProgrammesFromStore()
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    async onSucceed() {
      await this.getProgrammesFromStore()
    },
    async onSearchMainData() {
      await this.getProgrammesFromStore()
    },

    onImportSubject(data) {
      this.currentMainData = data
      this.$bvModal.show('modal-import-subject')
    },
  },
}
</script>
